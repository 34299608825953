import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import ButtonCircle3 from '../shared/Button/ButtonCircle3';
import ButtonPrimary from '../shared/Button/ButtonPrimary';
import ButtonSecondary from '../shared/Button/ButtonSecondary';
import InputNumber from '../shared/InputNumber/InputNumber';
import type { RootState } from '../store';
import type { CartItem } from '../store/cartSlice';
import { updateQuantity } from '../store/cartSlice';

export type CartSideBarProps = Record<string, never>;

const CartSideBar: React.FC<CartSideBarProps> = () => {
  const [isVisible, setIsVisible] = useState(false);

  const cartItems = useSelector((state: RootState) => state.cart.items);
  const dispatch = useDispatch();

  const handleOpenMenu = () => setIsVisible(true);
  const handleCloseMenu = () => setIsVisible(false);

  const handleQuantityChange = (productId: string, newQuantity: number) => {
    dispatch(updateQuantity({ productId, quantity: newQuantity }));
  };

  const renderProduct = (item: CartItem) => {
    const { productId, productName, image, price, size, color, quantity } = item;

    return (
      <div key={productId} className="flex py-5 last:pb-0">
        <div className="relative size-24 shrink-0 overflow-hidden rounded-xl">
          <img
            src={image}
            alt={productName}
            className="w-full h-full object-cover object-top"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col justify-between">
          <div>
            <div className="flex justify-between">
              <h3 className="font-medium">{productName}</h3>
              <span className="font-medium">${price}.00</span>
            </div>
            <p>Size: {size}</p>
            <p>Color: {color}</p>
          </div>

          <div className="flex w-full items-end justify-between text-sm">
            <div className="flex items-center gap-3">
              <AiOutlineDelete className="text-2xl" />
            </div>
            <div>
              <InputNumber
                value={quantity}
                onChange={(newQuantity) => handleQuantityChange(productId, newQuantity)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={handleCloseMenu}>
          <div className="z-max fixed inset-y-0 right-0 w-full max-w-md outline-none focus:outline-none md:max-w-md">
            <Transition.Child
              as={Fragment}
              enter="transition duration-100 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition duration-150 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div className="relative z-20">
                <div className="overflow-hidden shadow-lg ring-1 ring-black/5">
                  <div className="relative h-screen bg-white">
                    <div className="hiddenScrollbar h-screen overflow-y-auto p-5">
                      <div className="flex items-center justify-between">
                        <h3 className="text-xl font-semibold">Shopping cart</h3>
                        <ButtonCircle3 onClick={handleCloseMenu}>
                          <MdClose className="text-2xl" />
                        </ButtonCircle3>
                      </div>
                      <div className="divide-y divide-neutral-300">
                        {cartItems.length > 0 ? (
                          cartItems.map((item) => renderProduct(item))
                        ) : (
                          <p>Your cart is empty.</p>
                        )}
                      </div>
                    </div>
                    <div className="absolute bottom-0 left-0 w-full bg-neutral-50 p-5">
                      <p className="flex justify-between">
                        <span>
                          <span className="font-medium">Subtotal</span>
                          <span className="block text-sm text-neutral-500">
                            Shipping and taxes calculated at checkout.
                          </span>
                        </span>
                        <span className="text-xl font-medium">
                          $
                          {cartItems
                            .reduce((acc, item) => acc + item.price * item.quantity, 0)
                            .toFixed(2)}
                        </span>
                      </p>
                      <div className="mt-5 flex items-center gap-5">
                        <ButtonPrimary
                          href="/checkout"
                          onClick={handleCloseMenu}
                          className="w-full flex-1"
                        >
                          Checkout
                        </ButtonPrimary>
                        <ButtonSecondary
                          onClick={handleCloseMenu}
                          href="/cart"
                          className="w-full flex-1 border-2 border-primary text-primary"
                        >
                          View cart
                        </ButtonSecondary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-neutral-900/60" aria-hidden="true" />
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      <button
        type="button"
        onClick={handleOpenMenu}
        className="focus-visible:ring-opacity/75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white"
      >
        Cart({cartItems.length})
      </button>
      {renderContent()}
    </>
  );
};

export default CartSideBar;
