import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import React from 'react';
import { MdClose, MdKeyboardArrowDown, MdShoppingCart } from 'react-icons/md';
import logo from '../../images/logo1.jpg'; // Adjust the path if necessary
import { NavLinks } from '../../data/content';
import CartSideBar from '../CartSideBar';
import type { NavItemType } from '../NavItem';

export interface NavMobileProps {
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({ onClickClose }) => {
  const [isCartOpen, setIsCartOpen] = React.useState(false);

  const renderMenuChild = (
    item: NavItemType,
    itemClass = ' pl-3 font-medium ',
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pb-1 pl-6 text-base">
        {item.children?.map((i) => (
          <Disclosure key={i.id} as="li">
            <Link
              to={i.href || '#'}
              className={`mt-0.5 flex rounded-lg pr-4 text-sm hover:bg-neutral-100 ${itemClass}`}
              onClick={onClickClose}
            >
              <span
                role="button"
                tabIndex={0}
                className={`py-1 ${!i.children ? 'block w-full' : ''}`}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') onClickClose?.();
                }}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex grow items-center"
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex grow justify-end"
                  >
                    <MdKeyboardArrowDown className="text-xl" />
                  </Disclosure.Button>
                </span>
              )}
            </Link>
            {i.children && (
              <Disclosure.Panel>{renderMenuChild(i, 'pl-3')}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure key={index} as="li">
        <Link
          className="flex w-full items-center rounded-lg px-4 text-sm font-medium uppercase tracking-wide hover:bg-slate-100"
          to={item.href || '#'}
          onClick={onClickClose}
        >
          <span
            role="button"
            tabIndex={0}
            className={!item.children ? 'block w-full' : ''}
            onKeyPress={(e) => {
              if (e.key === 'Enter') onClickClose?.();
            }}
          >
            {item.name}
          </span>
          {item.children && (
            <span
              className="block grow"
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button as="span" className="flex grow justify-end">
                <MdKeyboardArrowDown className="text-xl text-black" />
              </Disclosure.Button>
            </span>
          )}
        </Link>
        {item.children && (
          <Disclosure.Panel>{renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="h-screen w-full divide-y divide-neutral-300 overflow-y-auto bg-white py-2 shadow-lg ring-1 transition">
      <div className="px-5 py-2">
        <img src={logo} alt="New Logo" width={100} height={40} />
        <div className="absolute right-2 top-2 p-1">
          <MdClose role="button" tabIndex={0} onClick={onClickClose} />
        </div>
      </div>
      <ul className="flex flex-col space-y-5 px-5 py-6">
        {NavLinks.map(renderItem)}
      </ul>
      <div className="mt-3 flex items-center justify-between px-5 py-3">
        <button
          type="button"
          className="flex items-center gap-2 font-semibold text-purple-500"
          onClick={() => setIsCartOpen(true)}
        >
          <MdShoppingCart className="text-2xl text-purple-500" />
          Cart
        </button>
      </div>

      {/* Render the Cart Sidebar */}
      {isCartOpen && <CartSideBar />}
    </div>
  );
};

export default NavMobile;
