import { Options } from "@splidejs/react-splide";
import { SplideSlide } from "@splidejs/react-splide";
import AutoScrollSlider from "../../shared/AutoScroll/AutoScrollSlider";

import brand1 from "../../images/brand1.png";
import brand2 from "../../images/brand2.png";
import brand3 from "../../images/brand3.png";
import brand4 from "../../images/brand4.png";
import brand5 from "../../images/brand5.png";

const BrandsSection = () => {
  const brands = [
    { id: "1", name: "Brand 1", logo: brand1 },
    { id: "2", name: "Brand 2", logo: brand2 },
    { id: "3", name: "Brand 3", logo: brand3 },
    { id: "4", name: "Brand 4", logo: brand4 },
    { id: "5", name: "Brand 5", logo: brand5 },
  ];

  const sliderOptions: Options = {
    autoScroll: { speed: 1 },
    arrows: false,
    perPage: 6,
    type: "loop",
    drag: false,
    pagination: false,
    gap: "40px",
    breakpoints: {
      640: { perPage: 3, gap: "20px" },
      768: { perPage: 4, gap: "30px" },
      1024: { perPage: 5, gap: "35px" },
      1280: { perPage: 6, gap: "40px" },
    },
  };

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Brands</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Partnering with the world&apos;s leading brands to bring you quality products
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 py-10">
          <AutoScrollSlider trigger options={sliderOptions}>
            {brands.map((brand) => (
              <SplideSlide key={brand.id}>
                <div className="px-6">
                  <div
                    className="aspect-square relative rounded-xl overflow-hidden bg-white 
                                 flex items-center justify-center grayscale hover:grayscale-0 
                                 transition-all duration-300 ease-in-out"
                  >
                    <img
                      src={brand.logo}
                      alt={brand.name}
                      className="object-contain p-4 w-full h-full"
                    />
                  </div>
                </div>
              </SplideSlide>
            ))}
          </AutoScrollSlider>
        </div>
      </div>
    </div>
  );
};

export default BrandsSection;
