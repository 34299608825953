import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';

interface Product {
  _id: string;
  name: string;
  image: string;
  store: string;
}

interface Discount {
  _id: string;
  product: Product;
  description: string;
  discountAmount: number;
  startDate: string;
  endDate: string;
  isActive: boolean;
}

const SectionCategories: React.FC = () => {
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const response = await axios.get<{ discounts: Discount[] }>(
          'https://ecom-api-a3zv.onrender.com/discount/discount'
        );
        setDiscounts(response.data.discounts);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch discounts:', error);
        setLoading(false);
      }
    };

    fetchDiscounts();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading discounts...</div>;
  }

  if (discounts.length === 0) {
    return <div className="text-center py-10">No discounts available at the moment.</div>;
  }

  const getRouteForStore = (store: string, productId: string) => {
    switch (store.toLowerCase()) {
      case 'clothes':
        return `/Cproducts/${productId}`;
      case 'toys':
        return `/Tproducts/${productId}`;
      case 'fireworks':
        return `/Fproducts/${productId}`;
      default:
        return `/products/${productId}`;
    }
  };

  return (
    <div className="container mx-auto px-4 py-10">
      <h2 className="text-3xl font-bold text-center mb-8">Exclusive Discounts</h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {discounts.map((discount) => (
          <div
            key={discount._id}
            className="relative h-[400px] w-full rounded-2xl overflow-hidden shadow-lg bg-cover bg-center"
            style={{
              backgroundImage: `url(${encodeURI(discount.product.image) || '/default-image.jpg'})`,
            }}
          >
            <div className="absolute inset-0 bg-black/40 p-6 flex flex-col justify-end">
              <h3 className="text-xl font-semibold text-white mb-2">
                {discount.product.name}
              </h3>
              <p className="text-white mb-4">{discount.description}</p>
              <div className="flex items-center gap-4">
                <ButtonPrimary
                  className="px-6 py-2"
                  onClick={() => navigate(getRouteForStore(discount.product.store, discount.product._id))}
                >
                  Get {Math.min(discount.discountAmount, 100)}% Off
                </ButtonPrimary>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionCategories;
