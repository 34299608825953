import { Link } from 'react-router-dom';
import { MdOutlineFilterVintage } from 'react-icons/md';

interface LogoProps {
  className?: string;
  src?: string;
}

const Logo: React.FC<LogoProps> = ({ src, className = '' }) => {
  return (
    <Link
      className={`flex cursor-pointer items-center gap-2 text-primary ${className}`}
      to="/"
    >
      {src ? (
        <img
          src={src}
          alt="Logo"
          width={120}
          height={80}
          className="object-contain"
        />
      ) : (
        <MdOutlineFilterVintage className="text-4xl" />
      )}
    </Link>
  );
};

export default Logo;
