import { Link } from "react-router-dom";
import React, { useState } from "react";
import { BsFacebook, BsInstagram, BsTiktok } from "react-icons/bs";
import { MdCopyright } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { footerData } from "../../data/content";

interface SocialLinks {
  instagram: string;
  facebook: string;
  tiktok: string;
}

const Footer: React.FC = () => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const [isExpanded, setIsExpanded] = useState(false);

  const socialLinksMap: Record<string, SocialLinks> = {
    '/RawadStores': {
      instagram: 'https://www.instagram.com/rawadstores.lb?igsh=OHo2MDJ3MmtueTZ3',
      facebook: 'https://www.facebook.com/RawadStores.lb?mibextid=ZbWKwL',
      tiktok: 'https://www.tiktok.com/@rawadstores?_t=8qvmXbYbOTA&_r=1',
    },
    '/KidsToys': {
      instagram: 'https://www.instagram.com/kidstoys.lb?igsh=MTh4dDE2eThwbGY2dw==',
      facebook: 'https://www.facebook.com/profile.php?id=100063859372968&mibextid=ZbWKwL',
      tiktok: 'https://www.tiktok.com/@kidstoys.lb?_t=8qvmcm8p52G&_r=1',
    },
    '/RawadDakdoukFireworks': {
      instagram: 'https://www.instagram.com/rawaddakdoukfireworks?igsh=ZnJlc3p3OXZqNW9p',
      facebook: 'https://www.facebook.com/RawadDakdoukFireworks?mibextid=ZbWKwL',
      tiktok: 'https://www.tiktok.com/@rawaddakdoukfireworks?_t=8qvmf4nxk1a&_r=1',
    },
    // Fallback default links
    default: {
      instagram: 'https://www.instagram.com/rawadstores.lb?igsh=OHo2MDJ3MmtueTZ3',
      facebook: 'https://www.facebook.com/RawadStores.lb?mibextid=ZbWKwL',
      tiktok: 'https://www.tiktok.com/@rawadstores?_t=8qvmXbYbOTA&_r=1',
    },
  };

  const socialLinks = socialLinksMap[currentRoute] || socialLinksMap["default"];

  const aboutUsContentMap: Record<string, string> = {
    "/RawadStores": "Welcome to Rawad Stores, where quality meets elegance and innovation...",
    "/KidsToys": "Welcome to Kids Toys, your trusted destination for a wide selection of high-quality toys...",
    "/RawadDakdoukFireworks": "Welcome to Rawad Dakdouk Fireworks, the premier source for extraordinary pyrotechnic experiences...",
    default: "Welcome to Dakdouk Global Gate, a dynamic and trusted holding company established in 1999...",
  };

  const getAboutUsContent = (route: string) => {
    if (route.startsWith("/Cproducts")) return aboutUsContentMap["/Cproducts"];
    if (route.startsWith("/Tproducts")) return aboutUsContentMap["/Tproducts"];
    if (route.startsWith("/Fproducts")) return aboutUsContentMap["/Fproducts"];
    return aboutUsContentMap["default"];
  };

  const aboutUsContent = getAboutUsContent(currentRoute);

  const toggleContent = () => setIsExpanded(!isExpanded);

  return (
    <div className="container mb-5">
      <div className="rounded-3xl bg-black text-white">
        <div className="grid gap-10 divide-x divide-neutral-500 lg:grid-cols-2 lg:gap-0">
          <div className="grid gap-y-10 px-10 pb-5 pt-16 md:grid-cols-3 lg:pr-20">
            {footerData.footerLinks.map((item) => (
              <div key={item.title} className="space-y-5">
                <h4 className="text-2xl font-medium">{item.title}</h4>
                {item.links.map((link) => (
                  <div key={link.name}>
                    <Link to={link.href}>{link.name}</Link>
                  </div>
                ))}
              </div>
            ))}
            <div className="flex items-center gap-5">
              <Link to={socialLinks.instagram}>
                <BsInstagram className="text-2xl" />
              </Link>
              <Link to={socialLinks.facebook}>
                <BsFacebook className="text-2xl" />
              </Link>
              <Link to={socialLinks.tiktok}>
                <BsTiktok className="text-2xl" />
              </Link>
            </div>
          </div>

          <div className="flex flex-col justify-center p-5 md:p-20">
            <h4 className="text-2xl font-medium">About Us</h4>
            <div className={`mt-3 ${isExpanded ? "overflow-y-auto max-h-96" : ""}`}>
              <p>
                {isExpanded ? aboutUsContent : aboutUsContent.slice(0, 150) + "..."}
              </p>
            </div>
            <button onClick={toggleContent} className="mt-3 text-blue-500 hover:underline">
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
        <div className="h-px w-full bg-neutral-500" />
        <div className="flex flex-col items-center justify-between gap-3 px-10 py-5 md:flex-row md:gap-0">
          <div className="flex items-center gap-1 text-sm md:text-base">
            <MdCopyright /> <span>2024 Dakdouk Global Gate. All rights reserved</span>
          </div>
          <div className="flex items-center gap-5">
            <Link to="/">Terms of service</Link>
            <Link to="/">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
