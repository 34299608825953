import { useEffect, useState } from 'react';
import { Options } from '@splidejs/react-splide';
import { SplideSlide } from '@splidejs/react-splide';
import { useNavigate } from 'react-router-dom';
import AutoScrollSlider from '../../shared/AutoScroll/AutoScrollSlider';

interface Product {
  _id: string;
  name: string;
  images: string[]; // Array of image URLs
  store: string;
}

const SectionSlider = () => {
  const [featuredProducts, setFeaturedProducts] = useState<Product[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const response = await fetch('https://ecom-api-a3zv.onrender.com/product/getslider');
        const data = await response.json();
        const products = Object.values(data).flat() as Product[];
        setFeaturedProducts(products);
      } catch (error) {
        console.error('Error fetching featured products:', error);
      }
    };

    fetchFeaturedProducts();
  }, []);

  const getRouteForStore = (store: string, productId: string) => {
    switch (store.toLowerCase()) {
      case 'clothes':
        return `/Cproducts/${productId}`;
      case 'toys':
        return `/Tproducts/${productId}`;
      case 'fireworks':
        return `/Fproducts/${productId}`;
      default:
        return `/products/${productId}`;
    }
  };

  const sliderOptions: Options = {
    autoScroll: {
      speed: 0.7,
    },
    arrows: false,
    perPage: 5,
    type: 'loop',
    drag: 'free',
    pagination: false,
    gap: '30px',
    breakpoints: {
      640: {
        perPage: 2,
        gap: '10px',
      },
      768: {
        perPage: 2,
        gap: '20px',
      },
      1024: {
        perPage: 3,
        gap: '40px',
      },
      1280: {
        perPage: 4,
        gap: '30px',
      },
      1536: {
        perPage: 4,
        gap: '30px',
      },
    },
  };

  return (
    <div className="border-y border-neutral-300 py-10">
      <AutoScrollSlider trigger options={sliderOptions}>
        {featuredProducts.map((product) => (
          <SplideSlide
            key={product._id}
            onClick={() => navigate(getRouteForStore(product.store, product._id))}
          >
            <div className="flex items-center gap-4 cursor-pointer p-4 hover:bg-gray-100 rounded-lg transition transform hover:scale-105">
              <div className="flex-shrink-0 h-24 w-24 overflow-hidden rounded-lg shadow-md">
                <img
                  src={product.images[0] || '/default-image.jpg'} // Display only the first image
                  alt={product.name}
                  className="object-cover object-center w-full h-full"
                />
              </div>
              <p className="font-semibold text-lg text-center text-gray-800">{product.name}</p>
            </div>
          </SplideSlide>
        ))}
      </AutoScrollSlider>
    </div>
  );
};

export default SectionSlider;
