import React, { FC, useEffect, useState } from "react";

export interface InputNumberProps {
  className?: string;
  value?: number; // For controlled input
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  label?: string;
  desc?: string;
  readOnly?: boolean; // Add readOnly prop
}

const InputNumber: FC<InputNumberProps> = ({
  className = "w-full",
  value,
  defaultValue = 1,
  min = 1,
  max = 99,
  onChange,
  label,
  desc,
  readOnly = false, // Default readOnly to false
}) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(value);
    }
  }, [value]);

  const handleClickDecrement = () => {
    if (min >= internalValue || readOnly) return; // Prevent changes if readOnly
    const newValue = internalValue - 1;
    setInternalValue(newValue);
    onChange && onChange(newValue);
  };

  const handleClickIncrement = () => {
    if ((max && max <= internalValue) || readOnly) return; // Prevent changes if readOnly
    const newValue = internalValue + 1;
    setInternalValue(newValue);
    onChange && onChange(newValue);
  };

  const renderLabel = () => (
    <div className="flex flex-col">
      <span className="font-medium">{label}</span>
      {desc && (
        <span className="text-xs font-normal text-neutral-500">{desc}</span>
      )}
    </div>
  );

  return (
    <div
      className={`nc-InputNumber flex items-center justify-between space-x-5 ${className}`}
    >
      {label && renderLabel()}

      <div className="nc-NcInputNumber__content flex w-[104px] items-center justify-between sm:w-28">
        <button
          className="flex h-8 w-8 items-center justify-center rounded-full border border-neutral-300 bg-white text-xl hover:border-neutral-700 focus:outline-none disabled:cursor-default disabled:opacity-50 disabled:hover:border-neutral-400"
          type="button"
          onClick={handleClickDecrement}
          disabled={min >= internalValue || readOnly}
        >
          -
        </button>
        <span className="block flex-1 select-none text-center leading-none">
          {internalValue}
        </span>
        <button
          className="flex h-8 w-8 items-center justify-center rounded-full border border-neutral-300 bg-white text-xl hover:border-neutral-700 focus:outline-none disabled:cursor-default disabled:opacity-50 disabled:hover:border-neutral-400"
          type="button"
          onClick={handleClickIncrement}
          disabled={max ? max <= internalValue || readOnly : false}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default InputNumber;
