import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom'; // Outlet for nested routes

import Header from '../components/Header/Header';
import Footer from '../shared/Footer/Footer';
import Loading from '../components/Loading'; // Adjust path as needed
import WhatsAppChat from '../components/WhatsAppChat';
import Providers from '../components/providers'; // Adjust path as needed

const AppLayout: React.FC = () => {
  return (
    <div>
      <Providers>
        <Header />
        <Suspense fallback={<Loading />}>
          <Outlet /> {/* Renders nested route components */}
        </Suspense>
        <Footer />
        <WhatsAppChat />
      </Providers>
    </div>
  );
};

export default AppLayout;
