import React from "react";
import { FaStarOfLife } from "react-icons/fa";
import Heading from "../../shared/Heading/Heading";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
// Import your CEO image
import ceoImage from "../../images/hanging2.png";

const SectionBiography = () => {
  return (
    <div className="container">
      <Heading className="mb-5 max-w-3xl" showDash>
        The Story Behind Our Success
      </Heading>

      <div className="grid gap-8 lg:grid-cols-12">
        {/* Left Column with Image */}
        <div className="lg:col-span-5">
          <div className="relative">
            <div className="aspect-[4/5] w-full overflow-hidden rounded-2xl">
              <img
                src={ceoImage}
                alt="Company Founder"
                className="object-cover w-full h-full"
              />
            </div>
            <div
              className="absolute bottom-6 left-6 right-6 rounded-xl p-4"
              style={{ background: "linear-gradient(90deg, #6a21f7, purple)" }}
            >
              <div className="text-white">
                <p className="text-sm font-medium">Founder & Visionary</p>
                <h3 className="text-2xl font-medium mt-1">John Anderson</h3>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column with Content */}
        <div className="lg:col-span-7 flex flex-col justify-center">
          <div className="space-y-8">
            {/* Vision Statement */}
            <div className="space-y-4">
              <ButtonSecondary className="border-2 bg-transparent">
                Our Vision
              </ButtonSecondary>
              <p className="text-xl">
                "Creating exceptional experiences through innovation and
                dedication to quality."
              </p>
            </div>

            {/* Bio Content */}
            <div className="space-y-4">
              <p className="text-gray-600">
                With over two decades of experience in fashion and retail, John
                has transformed his passion for style into a global movement.
                His journey began with a simple idea: make premium fashion
                accessible to everyone without compromising on quality.
              </p>
              <p className="text-gray-600">
                Today, under his leadership, we&apos;ve grown from a small
                boutique into an international brand, serving customers across
                continents while maintaining our commitment to sustainability
                and ethical fashion.
              </p>
            </div>

            {/* Achievements */}
            <div className="grid grid-cols-3 gap-4 pt-4">
              <div>
                <div className="flex items-center gap-2">
                  <FaStarOfLife
                    style={{ color: "#6a21f7" }}
                    className="text-xl"
                  />
                  <span className="text-2xl font-medium">20+</span>
                </div>
                <p className="text-gray-600 mt-1">Years Experience</p>
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <FaStarOfLife
                    style={{ color: "#6a21f7" }}
                    className="text-xl"
                  />
                  <span className="text-2xl font-medium">50+</span>
                </div>
                <p className="text-gray-600 mt-1">Global Partners</p>
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <FaStarOfLife
                    style={{ color: "#6a21f7" }}
                    className="text-xl"
                  />
                  <span className="text-2xl font-medium">100k+</span>
                </div>
                <p className="text-gray-600 mt-1">Happy Customers</p>
              </div>
            </div>

            {/* Social Proof */}
            <div
              className="rounded-xl p-6 mt-6"
              style={{
                background:
                  "linear-gradient(90deg, rgba(106, 33, 247, 0.1), rgba(128, 0, 128, 0.1))",
              }}
            >
              <div className="flex items-center gap-3">
                <div className="flex items-center">
                  <FaStarOfLife
                    style={{ color: "#6a21f7" }}
                    className="text-2xl"
                  />
                  <div
                    className="h-px w-24"
                    style={{
                      background: "linear-gradient(90deg, #6a21f7, purple)",
                    }}
                  />
                </div>
                <p className="text-lg font-medium">
                  Featured in Vogue, Forbes, and Business Insider
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionBiography;
