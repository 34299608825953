import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LuUser2 } from 'react-icons/lu';
import { RiSearch2Line, RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { NavLinks } from '../../data/content';
import clothes from '../../images/rawad stores logo-1.svg';
import fireworks from '../../images/Rawad Dakdouk Logo-1.svg';
import logo from '../../images/mainlogo.svg';
import toys from '../../images/kids toys qubes 2-1.svg';

import Logo from '../../shared/Logo/Logo';
import type { AppDispatch, RootState } from '../../store';
import { logout } from '../../store/authSlice';
import CartSideBar from '../CartSideBar';
import NavigationItem from '../NavItem';
import MenuBar from './MenuBar';

interface SearchQuery {
  name: string;
}

interface Product {
  _id: string;
  name: string;
  image: string;
  store: string;
  price: number;
}

const MainNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMobileSearch, setIsMobileSearch] = useState(false);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  // Determine the logo based on the current path
  let logoSrc;
  if (location.pathname.startsWith('/KidsToys') || location.pathname.startsWith('/Tproducts')) {
    logoSrc = toys;
  } else if (location.pathname.startsWith('/RawadDakdoukFireworks') || location.pathname.startsWith('/Fproducts')) {
    logoSrc = fireworks;
  } else if (location.pathname.startsWith('/RawadStores') || location.pathname.startsWith('/Cproducts')) {
    logoSrc = clothes;
  } else {
    logoSrc = logo;
  }

  const handleUserIconClick = () => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    setShowDropdown(false);
    navigate('/');
  };

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({ name: '' });
  const [searchResults, setSearchResults] = useState<Product[]>([]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery({ name: value });
    if (value) {
      fetchSuggestions(value);
    } else {
      setSearchResults([]);
    }
  };

  const fetchSuggestions = async (query: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://ecom-api-a3zv.onrender.com/product/search', {
        params: { name: query },
      });
      setSearchResults(response.data);
    } catch (err) {
      console.error('Error fetching search results:', err);
      setError('Failed to fetch search results. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleSearch = () => {
    setShowSearch((prev) => !prev);
  };

  const handleToggleMobileSearch = () => {
    setIsMobileSearch((prev) => !prev);
    setShowSearch(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target as Node)
    ) {
      setShowSearch(false);
      setIsMobileSearch(false);
    }
  };

  useEffect(() => {
    if (showSearch) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showSearch]);

  const handleProductClick = (id: string) => {
    navigate(`/search/${id}`);
  };

  return (
    <div className="container flex flex-col items-center justify-between p-4">
      <div className="w-full flex items-center justify-between">
        <div className="flex-1">
          <Logo src={logoSrc} />
        </div>

        <div className="hidden items-center gap-7 lg:flex">
          {NavLinks.map((item) => (
            <NavigationItem key={item.id} menuItem={item} />
          ))}
        </div>

        <div className="hidden flex-1 items-center justify-end gap-7 lg:flex">
          <div className="relative" ref={searchContainerRef}>
            <RiSearch2Line
              className="text-2xl cursor-pointer"
              onClick={handleToggleSearch}
            />
            {showSearch && (
              <div className="absolute right-0 bg-white shadow-lg rounded p-2 w-96">
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    name="name"
                    value={searchQuery.name}
                    onChange={handleSearchChange}
                    placeholder="Search products"
                    className="border rounded px-2 py-1 w-full"
                    autoFocus
                  />
                  <RiCloseLine
                    className="text-2xl cursor-pointer text-gray-600 hover:text-gray-800"
                    onClick={() => setShowSearch(false)}
                    title="Close"
                  />
                </div>
                {showSearch && searchResults.length > 0 && (
                  <div className="mt-2 bg-white shadow-lg rounded p-4 max-h-64 overflow-y-auto z-10">
                    {loading && <p>Loading...</p>}
                    {error && <p className="text-red-500">{error}</p>}
                    <ul className="list-none">
                      {searchResults.map((result) => (
                        <li
                          key={result._id}
                          className="mb-2 flex gap-4 items-center border-b pb-2 cursor-pointer hover:bg-gray-100 transition"
                          onClick={() => handleProductClick(result._id)}
                        >
                          <img
                            src={result.image}
                            alt={result.name}
                            width={64}
                            height={64}
                            className="object-cover rounded"
                          />
                          <div className="flex flex-col">
                            <span className="font-semibold text-lg">{result.name}</span>
                            <span className="text-sm text-gray-600">{result.store}</span>
                            <span className="text-blue-600 font-bold">${result.price}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="relative">
            <LuUser2 className="cursor-pointer text-2xl" onClick={handleUserIconClick} />
            {showDropdown && isAuthenticated && (
              <div className="absolute right-0 mt-2 w-40 rounded-md bg-white shadow-lg">
                <button
                  type="button"
                  onClick={handleLogout}
                  className="hover:bg-gray-100 block w-full px-4 py-2 text-left"
                >
                  Logout
                </button>
              </div>
            )}
          </div>

          <CartSideBar />
        </div>

        {/* Mobile menu and search */}
        <div className="lg:hidden flex items-center gap-4">
          <div className="flex items-center gap-2">
            <RiSearch2Line
              className="text-2xl cursor-pointer"
              onClick={handleToggleMobileSearch}
            />
            {isMobileSearch && (
              <div className="flex items-center gap-2 bg-white shadow-lg rounded p-2">
                <input
                  type="text"
                  name="name"
                  value={searchQuery.name}
                  onChange={handleSearchChange}
                  placeholder="Search products"
                  className="border rounded px-2 py-1 w-full"
                  autoFocus
                />
                <RiCloseLine
                  className="text-2xl cursor-pointer text-gray-600 hover:text-gray-800"
                  onClick={() => setIsMobileSearch(false)}
                  title="Close"
                />
              </div>
            )}
          </div>
          <MenuBar />
        </div>
      </div>

      {/* Mobile search results */}
      {isMobileSearch && showSearch && searchResults.length > 0 && (
        <div className="w-full mt-2 bg-white shadow-lg rounded p-4 max-h-64 overflow-y-auto z-10">
          {loading && <p>Loading...</p>}
          {error && <p className="text-red-500">{error}</p>}
          <ul className="list-none">
            {searchResults.map((result) => (
              <li
                key={result._id}
                className="mb-2 flex gap-4 items-center border-b pb-2 cursor-pointer hover:bg-gray-100 transition"
                onClick={() => handleProductClick(result._id)}
              >
                <img
                  src={result.image}
                  alt={result.name}
                  width={64}
                  height={64}
                  className="object-cover rounded"
                />
                <div className="flex flex-col">
                  <span className="font-semibold text-lg">{result.name}</span>
                  <span className="text-sm text-gray-600">{result.store}</span>
                  <span className="text-blue-600 font-bold">${result.price}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MainNav;
