import React from 'react';
import { useLocation } from 'react-router-dom';
import { MessageCircle } from 'lucide-react';

const WhatsAppChat = () => {
  const location = useLocation(); // Get the current location
  const pathname = location.pathname; // Extract the pathname

  // Set phone numbers based on the current route
  const phoneNumber = (() => {
    if (pathname.startsWith('/KidsToys') || pathname.startsWith('/Tproducts')) {
      return "1112223333"; // Phone number for Kids Toys and Tproducts routes
    } else if (pathname.startsWith('/RawadDakdoukFireworks') || pathname.startsWith('/Fproducts')) {
      return "4445556666"; // Phone number for Fireworks and Fproducts routes
    } else if (pathname.startsWith('/RawadStores') || pathname.startsWith('/Cproducts')) {
      return "7778889999"; // Phone number for Rawad Stores and Cproducts routes
    } else {
      return "0001112222"; // Default phone number for other routes
    }
  })();

  const message = "Hello! I'm interested in your products.";

  const handleClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="fixed bottom-8 right-8 z-50">
      {/* Pulse animation ring */}
      <div className="absolute inset-0 animate-ping-slow rounded-full bg-green-400 opacity-25"></div>

      {/* Main button */}
      <button
        onClick={handleClick}
        className="relative flex items-center gap-3 bg-white hover:bg-green-50 pl-4 pr-6 py-3 rounded-full shadow-lg transition-all duration-300 group border border-green-100"
        aria-label="Chat on WhatsApp"
      >
        {/* WhatsApp Icon Container */}
        <div className="flex items-center justify-center w-10 h-10 bg-green-500 rounded-full">
          <MessageCircle className="w-5 h-5 text-white" />
        </div>

        {/* Text */}
        <span className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
          Chat with us
        </span>
      </button>

      {/* Tooltip */}
      <div className="absolute -top-12 right-0 bg-gray-800 text-white text-xs py-2 px-3 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
        We typically respond in minutes!
      </div>
    </div>
  );
};

export default WhatsAppChat;
