import { Popover, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import type { FC, ReactNode } from 'react';
import React, { Fragment, useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';

export interface MegaMenuItem {
  id: string;
  image: string;
  title: string;
  items: NavItemType[];
}

export interface NavItemType {
  label: ReactNode;
  id: string;
  name: string;
  isNew?: boolean;
  href: string;
  targetBlank?: boolean;
  children?: NavItemType[];
  megaMenu?: MegaMenuItem[];
  type?: 'dropdown' | 'none';
}

export interface NavigationItemProps {
  menuItem: NavItemType;
}

type NavigationItemWithRouterProps = NavigationItemProps;

const NavigationItem: FC<NavigationItemWithRouterProps> = ({ menuItem }) => {
  const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);

  const location = useLocation();
  const locationPathName = location.pathname;

  useEffect(() => {
    setMenuCurrentHovers([]);
  }, [locationPathName]);

  const onMouseEnterMenu = (id: string) => {
    setMenuCurrentHovers((state) => [...state, id]);
  };

  const onMouseLeaveMenu = (id: string) => {
    setMenuCurrentHovers((state) => {
      return state.filter((item, index) => {
        return item !== id && index < state.indexOf(id);
      });
    });
  };

  const renderMainItem = (item: NavItemType) => {
    return (
      <Link
        rel="noopener noreferrer"
        className="group relative inline-flex items-center font-medium"
        to={item.href || '/'}
        target={item.targetBlank ? '_blank' : undefined}
      >
        <span className="relative z-10">{item.name}</span>
        {item.type && (
          <BsChevronDown className="-mr-1 ml-1 size-4" aria-hidden="true" />
        )}
        {/* Gradient background on hover */}
        <span className="absolute inset-0 rounded-md bg-gradient-to-r from-[#6a21f7] to-purple-600 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
      </Link>
    );
  };

  const renderDropdownMenuNavlink = (item: NavItemType) => {
    return (
      <Link
        target={item.targetBlank ? '_blank' : undefined}
        rel="noopener noreferrer"
        className="hover group relative flex items-center rounded-md px-4 py-2 font-normal"
        to={item.href || ''}
      >
        <span className="relative z-10">{item.name}</span>
        {item.type && (
          <BsChevronDown className="-mr-1 ml-1 size-4" aria-hidden="true" />
        )}
        {/* Gradient background on hover */}
        <span className="absolute inset-0 rounded-md bg-gradient-to-r from-[#6a21f7] to-purple-600 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
      </Link>
    );
  };

  const renderDropdownMenuNavlinkHasChild = (item: NavItemType) => {
    const isHover = menuCurrentHovers.includes(item.id);
    return (
      <Popover
        as="li"
        key={item.id}
        className="menu-item menu-dropdown relative flex items-center px-2"
        onMouseEnter={() => onMouseEnterMenu(item.id)}
        onMouseLeave={() => onMouseLeaveMenu(item.id)}
      >
        {() => (
          <>
            <div>{renderDropdownMenuNavlink(item)}</div>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="sub-menu absolute left-full top-0 z-10 w-56 pl-2"
              >
                <ul className="ring-opacity/5 relative grid space-y-1 rounded-lg bg-white py-4 shadow-lg ring-1 ring-black">
                  {item.children?.map((i) => {
                    if (i.type) {
                      return renderDropdownMenuNavlinkHasChild(i);
                    }
                    return (
                      <li key={i.id} className="px-2">
                        {renderDropdownMenuNavlink(i)}
                      </li>
                    );
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderDropdownMenu = (menuDropdown: NavItemType) => {
    const isHover = menuCurrentHovers.includes(menuDropdown.id);
    return (
      <Popover
        as="li"
        className={`relative flex items-center ${
          menuDropdown.isNew ? 'menuIsNew_lv1' : ''
        } menu-item menu-dropdown`}
        onMouseEnter={() => onMouseEnterMenu(menuDropdown.id)}
        onMouseLeave={() => onMouseLeaveMenu(menuDropdown.id)}
      >
        {() => (
          <>
            <div>{renderMainItem(menuDropdown)}</div>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="sub-menu absolute left-0 top-full z-10 w-56 will-change-transform"
              >
                <ul className="ring-opacity/5 relative grid space-y-1 rounded-lg bg-white py-4 shadow-lg ring-1 ring-black">
                  {menuDropdown.children?.map((i) => {
                    if (i.type) {
                      return renderDropdownMenuNavlinkHasChild(i);
                    }
                    return (
                      <li
                        key={i.id}
                        className={`px-2 ${i.isNew ? 'menuIsNew' : ''}`}
                      >
                        {renderDropdownMenuNavlink(i)}
                      </li>
                    );
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  switch (menuItem.type) {
    case 'dropdown':
      return renderDropdownMenu(menuItem);
    default:
      return (
        <li className="menu-item flex items-center">
          {renderMainItem(menuItem)}
        </li>
      );
  }
};

export default NavigationItem;
