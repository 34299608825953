import React from 'react';

import SectionCategories from '../components/home/SectionCategories';
import SectionHeader from '../components/home//SectionHeader';
import SectionSlider from '../components/home/SectionSlider';
import SectionStyle from '../components/home/SectionStyle';
import SectionBiography from '../components/home/SectionBiography';
import BrandsSection from '../components/home/BrandsSection';

const Home: React.FC = () => {
  return (
    <div>
      <div className="my-7">
        <SectionHeader />
      </div>

      <div className="pt-10">
        <SectionSlider />
      </div>

      <div className="pb-24">
        <SectionCategories />
      </div>

      <div className="pb-24">
        <SectionStyle />
      </div>
      <BrandsSection />
      <SectionBiography />
    </div>
  );
};

export default Home;
