import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the state interface
// Define the state interface
export interface AuthState {
  isAuthenticated: boolean;
  user: null | {
    id: string; // Add _id property to the user object
    name: string;
    email: string;
  };
  loading: boolean;
  error: null | string;
}


let parsedUser = null;
try {
  const userFromStorage = localStorage.getItem('user');
  parsedUser = userFromStorage ? JSON.parse(userFromStorage) : null;
} catch (error) {
  parsedUser = null;
}

const initialState: AuthState = {
  isAuthenticated: !!parsedUser,
  user: parsedUser,
  loading: false,
  error: null,
};

// Async thunk for signup
export const signupUser = createAsyncThunk(
  'auth/signupUser',
  async (
    { name, email, password, phoneNumber }: { name: string; email: string; password: string; phoneNumber: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        'https://ecom-api-a3zv.onrender.com/user/register',
        { name, email, password, phoneNumber }
      );
      return response.data; // Assuming response includes user data
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        'https://ecom-api-a3zv.onrender.com/user/login',
        { email, password }
      );
      return response.data;
    } catch (error: any) {
      const errorMessage =
      error.response?.data?.message || 'An unexpected error occurred';
    return rejectWithValue(errorMessage);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem('user'); // Clear local storage on logout
    },
  },
  extraReducers: (builder) => {
    builder
      // Signup User
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        localStorage.setItem('user', JSON.stringify(action.payload.user)); // Store user data in local storage
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Login User
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user; // Ensure the user object includes id, name, email, etc.
        localStorage.setItem('user', JSON.stringify(action.payload.user)); // Store in localStorage
      })      
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
