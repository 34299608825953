import React, { useRef } from 'react';
import { headerSection } from '../../data/content';
import heroImage from '../../images/shipping-container-with-estonia-flag-transparent-background_35913-3304-removebg-preview.png';
import ButtonSecondary from '../../shared/Button/ButtonSecondary';
import StoresSection from './StoresSection';

const SectionHeader = () => {
  const storesSectionRef = useRef<HTMLDivElement | null>(null);

  const scrollToStores = () => {
    if (storesSectionRef.current) {
      storesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Header Section */}
      <div className="container">
        <div
          className="items-stretch justify-between rounded-3xl lg:flex"
          style={{ background: 'linear-gradient(90deg, #6a21f7, purple)' }}
        >
          <div className="basis-1/2 space-y-10 py-10 pl-5 text-white md:pl-10">
            <p className="text-2xl font-medium">{headerSection.title}</p>
            <div
              className="flex items-center gap-1 text-[40px] font-bold md:text-[70px]"
              style={{ lineHeight: '1em' }}
            >
              {headerSection.heading}
            </div>
            <div className="flex items-center gap-7">
              <ButtonSecondary onClick={scrollToStores}>Shop Now</ButtonSecondary>
            </div>
          </div>
          <div className="flex basis-[47%] items-end overflow-hidden">
            <img
              src={heroImage}
              alt="hero image"
              className="-mb-16 w-full object-bottom"
            />
          </div>
        </div>
      </div>

      {/* Add a margin or padding here to create space between header and StoresSection */}
      <div style={{ height: '50px' }} /> {/* Temporary spacer */}

      {/* Stores Section, positioned after the header */}
      <StoresSection ref={storesSectionRef} />
    </>
  );
};

export default SectionHeader;
