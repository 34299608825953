import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import clothes from '../../images/clothes.png';
import fireworks from '../../images/fireworks.png';
import toys from '../../images/toys.png';

const StoresSection = forwardRef<HTMLDivElement>((props, ref) => {
  const navigate = useNavigate();

  const stores = [
    {
      id: 'clothes',
      name: 'Houseware',
      description: 'Latest houseware trends.',
      image: clothes,
      path: '/RawadStores',
    },
    {
      id: 'toys',
      name: 'Toys',
      description: 'Fun toys for all ages.',
      image: toys,
      path: '/KidsToys',
    },
    {
      id: 'fireworks',
      name: 'Fireworks',
      description: 'Brighten up your celebrations.',
      image: fireworks,
      path: '/RawadDakdoukFireworks',
    },
  ];

  const handleStoreClick = (path: string) => {
    navigate(path);
  };

  return (
    <div ref={ref} className="my-7 px-4">
      <h2 className="mb-10 text-center text-3xl font-bold">Our Stores</h2>
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
        {stores.map((store) => (
          <div
            key={store.id}
            className="group relative cursor-pointer overflow-hidden rounded-lg shadow-lg transition-shadow duration-300 hover:shadow-2xl"
            onClick={() => handleStoreClick(store.path)}
            onKeyPress={(e) => e.key === 'Enter' && handleStoreClick(store.path)}
            role="button"
            tabIndex={0}
          >
            <div className="relative h-60 w-full">
              <img
                src={store.image}
                alt={store.name}
                className="transition-transform duration-300 group-hover:scale-105 object-contain w-full h-full"
              />
            </div>
            <div className="absolute inset-0 flex items-center justify-center bg-black/40 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
              <div className="p-4 text-center text-white">
                <h3 className="mb-2 text-2xl font-semibold">{store.name}</h3>
                <p className="text-sm">{store.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

StoresSection.displayName = 'StoresSection';

export default StoresSection;
