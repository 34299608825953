import React from 'react';

import type { ButtonProps } from '../../shared/Button/Button';
import Button from '../../shared/Button/Button';

export interface ButtonPrimaryProps extends ButtonProps {
  href?: any;
  textClassName?: string;
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = '',
  textClassName = '',
  ...args
}) => {
  return (
    <Button
      className={`disabled:bg-opacity/70 rounded-full bg-gradient-to-r from-[#6a21f7] to-purple-600 ${textClassName || 'text-white'} transition-transform duration-300 hover:scale-105 ${className}`}
      {...args}
    >
      {args.children}
    </Button>
  );
};

export default ButtonPrimary;
