import React from 'react';
import { FaStarOfLife } from 'react-icons/fa';
import { promoSection } from '../../data/content';
import port from '../../images/port.jpg';
import woman from '../../images/hanging2.png';
import ButtonSecondary from '../../shared/Button/ButtonSecondary';
import Heading from '../../shared/Heading/Heading';

const SectionStyle = () => {
  return (
    <div className="container">
      <Heading className="mb-5 max-w-3xl" showDash>
        {promoSection.heading}
      </Heading>
      <div className="grid gap-5 lg:grid-cols-2">
        <div className="items-stretch gap-5 md:flex">
          <div className="basis-2/5 space-y-16">
            <div className="relative w-full">
              <img
                src={port}
                alt="photo"
                className="w-full rounded-2xl"
              />
            </div>
          </div>
          <div className="basis-3/5">
            <div className="h-5 w-full md:h-[250px]" />

            <div
              className="space-y-5 rounded-2xl p-5 text-white"
              style={{ background: 'linear-gradient(90deg, #6a21f7, purple)' }}
            >
              <ButtonSecondary className="border-2 border-white bg-transparent">
                2025 LogBook
              </ButtonSecondary>
              <h2 className="text-3xl font-medium">{promoSection.promoTitle}</h2>
              <span className="text-3xl font-medium text-secondary">
                #NewYear #Christmas
              </span>
            </div>
          </div>
        </div>

        <div className="space-y-10 md:pt-40 lg:pt-0">
          <div className="relative flex h-[350px] w-full items-center justify-center rounded-2xl bg-secondary">
            <img
              src={woman}
              alt="woman"
              className="absolute bottom-0 mx-auto w-4/5 object-cover md:w-3/5"
            />
          </div>

          <div className="flex items-center gap-3">
            <div className="hidden items-center md:flex">
              <FaStarOfLife style={{ color: '#6a21f7' }} className="text-4xl" />
              <div
                className="h-px w-48"
                style={{ background: 'linear-gradient(90deg, #6a21f7, purple)' }}
              />
            </div>
            <div className="space-y-5">
              <p className="text-xl font-medium">{promoSection.review.quote}</p>
              <p style={{ color: '#6a21f7' }} className="text-lg font-medium">
                - {promoSection.review.reviewer}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionStyle;
